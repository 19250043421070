module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Salt Building Group - Maintenance | Renovations | Building | Rectifications","short_name":"Salt Building Group","description":"Based on the Gold Coast, Salt Building Group service not only the local area but extend to surrounding suburbs & Northern NSW.","lang":"en","start_url":"/","icon":"src/images/favicon.png","background_color":"#000000","theme_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"018102aaa4338686593a33c85c5e01fd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
